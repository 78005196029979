#home {
  .heading-hero {
    padding-bottom: 130px;

    @include media-breakpoint-up(md) {
      padding-bottom: 150px;
    }
  }

  .heading {
    font-size: $h4-font-size;
    font-weight: $font-weight-bolder;
    max-width: 857px;

    @include media-breakpoint-up(md) {
      font-size: $display5-size;
    }
  }

  .hero-image .bg-image {
    padding-top: 75%;
    background-position-x: -190px;

    @include media-breakpoint-up(md) {
      padding-top: 43%;
      background-position-x: 0;
    }
  }

  .portfolio {
    .nav {
      .nav-item {
        // border-right: 1px solid $gray-300;
        height: 100px;

        @include media-breakpoint-up(md) {
          // height: 200px;
        }

        @include media-breakpoint-up(md) {
          border-right: 1px solid $gray-300;
          height: 100%;
        }

        &:last-child {
          border-right: none;
        }
      }

      .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
        width: 80%;
        margin: auto;

        @include media-breakpoint-up(md) {
          width: 70%;
        }

        @include media-breakpoint-up(lg) {
          width: 60%;
        }

        img {
          max-width: 80%;
          filter: grayscale(100%);
          @include transition;

          @include media-breakpoint-up(lg) {
            max-width: 80%;
          }
        }
      }

      .nav-link.active,
      .nav-link:hover {
        .logo-wrapper img {
          filter: grayscale(0);
          @include transition;
        }
      }
    }

    .bg-image {
      @include media-breakpoint-down(md) {
        padding-top: 50%;
      }
    }
  }

  .testimonials {
    padding-top: 50px;

    @include media-breakpoint-up(lg) {
      padding-top: 90px;
    }

    .bg-image-wrapper {
      &:first-of-type .blockquote {
        &:before {
          content: '';
          background: url('../img/home/tvp-home-testimonial-quote-teal.svg')
            no-repeat 0 0;
          width: 50px;
          height: 50px;
          background-size: 50px 50px;
          position: absolute;
          top: 0;
          left: 14px;

          @include media-breakpoint-up(lg) {
            left: -28px;
          }

          @include media-breakpoint-up(xl) {
            left: -34px;
          }
        }
      }

      &:last-of-type .blockquote {
        &:after {
          content: '';
          background: url('../img/home/tvp-home-testimonial-quote-peach.svg')
            no-repeat 0 0;
          width: 50px;
          height: 50px;
          background-size: 50px 50px;
          position: absolute;
          top: 0;
          right: 14px;

          @include media-breakpoint-up(lg) {
            top: auto;
            bottom: 0;
            right: 8px;
          }

          @include media-breakpoint-up(xl) {
            right: 0px;
          }
        }
      }
    }

    .bg-image {
      background-size: contain;
      overflow: visible;
    }

    .blockquote {
      padding: 3.5rem 0 3.5rem 0;
      @include media-breakpoint-up(lg) {
        font-size: $font-size-lg;
        position: absolute;
        top: 0;
        padding: 50px 75px 50px 40px;
      }

      @include media-breakpoint-up(2xl) {
        font-size: $h5-font-size;
        padding: 50px 95px 50px 60px;
      }

      .quote {
        font-family: $font-family-secondary;

        @include media-breakpoint-up(lg) {
        }
      }
    }
  }
}
