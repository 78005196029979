#contact {
  .hero {
    padding-top: 35%;

    @include media-breakpoint-up(md) {
      padding-top: 25%;
    }
  }

  form {
    label {
      font-weight: $font-weight-bold;
      font-size: $font-size-sm;
      text-transform: uppercase;
    }

    input,
    textarea {
      background-color: $gray-100;
      border-radius: 0;

      &::placeholder {
        color: $gray-400;
      }
    }
  }
}