#case-study {
  .hero {
    padding-top: 35%;
    background-position-y: -60px;

    @include media-breakpoint-up(md) {
      padding-top: 25%;
      background-position-y: -300px;
    }
  }

  table {
    tbody {
      font-size: $font-size-base;
    }
  }
}
