#team {
  .hero {
    padding-top: 30%;
    background-position-y: 20%;
  }

  .bios {
    .block {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 250px;
        height: 250px;

        @include media-breakpoint-up(sm) {
          width: 320px;
          height: 320px;
        }

        @include media-breakpoint-up(md) {
          width: 390px;
          height: 360px;
        }

        @include media-breakpoint-up(lg) {
          background-size: 70%;
          width: 320px;
          height: 320px;
        }

        @include media-breakpoint-up(xl) {
          background-size: 90%;
          width: 390px;
          height: 360px;
        }
      }

      &:nth-child(1):before {
        background: url("../img/team/tvp-team-frame-01.svg") no-repeat 0 0;
      }

      &:nth-child(2):before {
        background: url("../img/team/tvp-team-frame-02.svg") no-repeat 0 0;
      }

      &:nth-child(3):before {
        background: url("../img/team/tvp-team-frame-03.svg") no-repeat 0 0;
      }
    }

    i {
      display: inline-block;
    }

    .read-more {
      display: none;
    }

    .view-less {
      display: inline;
    }

    .collapsed {
      .read-more {
        display: inline;
      }

      .view-less {
        display: none;
      }

      i {
        transform: rotate(180deg);
      }
    }
  }
}
