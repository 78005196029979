#impact {
  .hero {
    padding-top: 35%;

    @include media-breakpoint-up(md) {
      padding-top: 25%;
    }
  }

  .icon {
    width: 60%;
  }

  .case-studies {
    .case-study {
      &:nth-child(odd):before {
        @include media-breakpoint-up(xl) {
          content: "";
          background: url("../img/impact/tvp-impact-case-study-frame-01.svg") no-repeat 0 0;
          position: absolute;
          top: -25px;
          left: 10px;
          width: 390px;
          height: 360px;
        }
      }

      &:nth-child(even):after {
        @include media-breakpoint-up(xl) {
          content: "";
          background: url("../img/impact/tvp-impact-case-study-frame-02.svg") no-repeat 0 0;
          position: absolute;
          top: -25px;
          right: 10px;
          width: 246px;
          height: 360px;
        }
      }

      .bg-image {
        @include media-breakpoint-down(md) {
          padding-top: 50%;
        }
      }
    }
  }

  .testimonials {
    .col-12:before {
      @include media-breakpoint-up(md) {
        content: "";
        background: url("../img/impact/tvp-impact-testimonial-frame.svg") no-repeat 0 0;
        position: absolute;
        width: 90%;
        height: 380px;
        background-size: contain;
      }

      @include media-breakpoint-up(lg) {
        width: 93%;
      }

      @include media-breakpoint-up(xl) {
        width: 94.4%;
      }

      @include media-breakpoint-up(2xl) {
        width: 95%;
      }
    }

    .carousel {
      @include media-breakpoint-up(md) {
        padding: 37px 53px;
      }

      @include media-breakpoint-up(lg) {
        padding: 58px;
      }

      @include media-breakpoint-up(xl) {
        padding: 73px;
      }

      @include media-breakpoint-up(2xl) {
        padding: 124px;
      }

      .carousel-indicators {
        position: absolute;
        bottom: -50px;

        @include media-breakpoint-up(md) {
          bottom: -30px;
        }

        @include media-breakpoint-up(lg) {
          bottom: -50px;
        }

        @include media-breakpoint-up(xl) {
          bottom: -50px;
        }

        @include media-breakpoint-up(2xl) {
          bottom: -30px;
        }

        li {
          width: 30px;
          height: 5px;
          margin: 0 5px;

          &.active {
            background-color: $teal;
          }
        }
      }

      .blockquote {
        @include media-breakpoint-only(md) {
          font-size: $font-size-sm;
        }
      }
    }
  }
}
