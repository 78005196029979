#portfolio {
  .hero {
    padding-top: 35%;

    @include media-breakpoint-up(md) {
      padding-top: 25%;
    }
  }

  .logos {
    .text-muted {
      font-size: $font-size-sm;
    }

    .nav {
      .nav-item {
        @include media-breakpoint-up(lg) {
          border-right: 1px solid $gray-300;
          height: 100%;
        }

        &:last-child {
          border-right: none;
        }
      }

      .logo-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        width: 70%;
        margin: auto;

        @include media-breakpoint-up(md) {
          width: 50%;
          height: 100px;
        }

        @include media-breakpoint-up(lg) {
          width: 60%;
        }

        img {
          width: 100%;
        }
      }
    }

    p {
      @include media-breakpoint-up(lg) {
        padding-right: 30rem;
      }
    }
  }

  .portfolio {
    &.offset {
      background-color: transparent;
    }

    .bg-image.aspect-1x1 {
      padding-top: 60%;

      @include media-breakpoint-up(md) {
        padding-top: 100%;
      }
    }

    .btn {
      font-size: $font-size-sm;

      @include media-breakpoint-up(sm) {
        font-size: $font-size-base;
      }
    }

    .break {
      flex-basis: 100%;
      height: 0;
    }
  }
}
