// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
  (
    '100': $gray-100,
    '200': $gray-200,
    '300': $gray-300,
    '400': $gray-400,
    '500': $gray-500,
    '600': $gray-600,
    '700': $gray-700,
    '800': $gray-800,
    '900': $gray-900
  ),
  $grays
);

$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange-lighter: #ffd7be;
$orange-light: #fda974;
$orange: #fa5a35;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$green-100: #3D625C;
$green-200: #34555F;
$green-300: #212E2E;
$teal: #5bddc7;
$cyan: #5ac3e3;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
  (
    'blue': $blue,
    'indigo': $indigo,
    'purple': $purple,
    'pink': $pink,
    'red': $red,
    'orange-lighter': $orange-lighter,
    'orange-light': $orange-light,
    'orange': $orange,
    'yellow': $yellow,
    'green': $green,
    'green-100': $green-100,
    'green-200': $green-200,
    'green-300': $green-300,
    'teal': $teal,
    'cyan': $cyan,
    'white': $white,
    'gray': $gray-600,
    'gray-dark': $gray-800
  ),
  $colors
);

$primary: $blue !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    'primary': $primary,
    'secondary': $secondary,
    'success': $success,
    'info': $info,
    'warning': $warning,
    'danger': $danger,
    'light': $light,
    'dark': $dark,
    'cyan': $cyan,
    'green-200': $green-200
  ),
  $theme-colors
);

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    ),
    6: (
      $spacer * 3.25
    ),
    7: (
      $spacer * 3.5
    ),
    8: (
      $spacer * 4
    ),
    9: (
      $spacer * 5.5
    ),
    10: (
      $spacer * 6.5
    )
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    65: 65%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// Body
//
// Settings for the `<body>` element.

$max-width: 1140px;

$body-bg: $white !default;
$body-color: $gray-900 !default;

// Links
//
// Style anchor elements.

$link-color: $cyan;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  xsm: 330px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  2xl: 1440px,
  3xl: 1560px
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  2xl: 1440px,
  3xl: 1560px
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
$grid-gutter-width: 70px !default;
$grid-row-columns: 6 !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$enable-responsive-font-sizes: true;

// stylelint-disable value-keyword-case
$font-family-sans-serif: sofia-pro, sans-serif;
$font-family-base: $font-family-sans-serif !default;
// stylelint-enable value-keyword-case

$font-family-secondary: harfang-pro, serif;

$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.125;
$font-size-sm: $font-size-base * 0.875 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.75;

$h1-font-size: $font-size-base * 2.125;
$h2-font-size: $font-size-base * 2 !default;
$h3-font-size: $font-size-base * 1.75 !default;
$h4-font-size: $font-size-base * 1.5 !default;
$h5-font-size: $font-size-base * 1.25 !default;
$h6-font-size: $font-size-base !default;

$headings-margin-bottom: $spacer / 2 !default;
$headings-font-family: $font-family-secondary;
$headings-font-weight: 400 !default;
$headings-line-height: 1.2 !default;
$headings-color: null !default;

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;
$display5-size: 2.625rem;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;
$display-line-height: $headings-line-height !default;

$lead-font-size: $font-size-base * 1.25 !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: $gray-400 !default;

$btn-border-radius: 0;