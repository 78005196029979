html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  color: $body-color;
  background: transparent linear-gradient(180deg, $green-100 0%,
  darken(saturate(adjust-hue($green-100, 5), 0.60), 0.78) 36%,
  lighten(desaturate(adjust-hue($green-200, -18), 4.51), 1.37) 54%, $green-200 100%) 0% 0% no-repeat padding-box;
  overflow-x: hidden;
}

section {
  padding: 67px 0;

  @include media-breakpoint-up(lg) {
    padding: 82px 0;
  }
}