body {
  font-size: $font-size-sm;
  line-height: $line-height-base;
  letter-spacing: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include media-breakpoint-up(md) {
    font-size: $font-size-base;
  }

  @include media-breakpoint-up(lg) {
    font-size: $font-size-lg;
  }
}

h1,
h2,
h3,
h4,
h5,
p,
a {
  @include transition();
  line-height: $line-height-base - .25;
}