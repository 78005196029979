#pageHeader {
  box-shadow: none;

  .navbar-wrapper {
    @include media-breakpoint-up(xl) {
      max-width: 1140px;
    }
  }
  .navbar-collapse {
    @include media-breakpoint-down(md) {
      // margin-top: -2px;
      top: 0;
      box-shadow: 0 2px 3px rgba($black, 0.5);
    }
  }
  .navbar {
    height: 83px;
    width: $max-width;
    width: 100%;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    @include transition;
    z-index: 1;

    @include media-breakpoint-up(xl) {
      height: 80px;
    }

    &.navbar-hide {
      top: -83px;

      @include media-breakpoint-up(lg) {
        top: -89px;
      }
    }

    .container {
      @include media-breakpoint-down(lg) {
        width: 100%;
        max-width: 100%;
        margin-right: auto;
        margin-left: auto;
      }

      @include media-breakpoint-up(xl) {
        padding-left: 107px;
      }
    }

    @include media-breakpoint-up(xl) {
      height: 87px;
    }

    .navbar-brand-wrapper {
      padding: 0 1.5rem;
      max-width: 100%;
      z-index: 2;

      @include media-breakpoint-up(md) {
        padding: 0 3.5rem;
      }

      @include media-breakpoint-up(xl) {
        padding: 0 1.5rem;
        box-shadow: none;
      }
    }

    .navbar-brand {
      color: $green-300;
      width: 190px;

      @include media-breakpoint-up(sm) {
        width: 240px;
      }

      img {
        width: 100%;
      }
    }

    .navbar-toggler {
      border: none;
      clip-path: inset(15px 0 10px);
      &:focus {
        border: 0;
        outline: none;
        box-shadow: none;
      }
      &.collapsed {
        -webkit-clip-path: inset(15px, 43px, 25px, 0px);
        clip-path: inset(0);
      }

      .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 27 27' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(90, 195, 227)' stroke-width='3' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        width: 1.8;
      }
    }

    .navbar-nav {
      // height: 90px;

      .nav-item {
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        text-align: center;
        font-weight: $font-weight-bold;
        letter-spacing: 1px;
        padding: 1rem;
        margin: 0 7px;
        height: 100%;

        &.active {
          border-bottom: 9px solid $orange-light;

          .nav-link {
            margin-bottom: -9px;
          }
        }

        &.nav-item-alt {
          background: #ccedf7;
          width: 100%;
          padding: 1.4375rem;

          @include media-breakpoint-up(xl) {
            width: 110px;
          }

          .nav-link {
            padding: 0;
            font-size: 0.9375rem;
          }
        }

        .nav-link {
          color: $green-300;
          padding: 0;
          font-size: $font-size-base;

          @include media-breakpoint-up(xl) {
            padding: 1.1rem 0;
          }
        }
      }
    }

    .navbar-collapse {
      position: relative;
      top: -1px;
      background-color: $white;
      z-index: 1;

      @include media-breakpoint-up(md) {
        top: -2px;
      }

      @include media-breakpoint-up(xl) {
        background-color: transparent;
      }
    }
  }
}

#pageContent {
  padding-top: 83px;

  .hero {
    // z-index: -1;
    pointer-events: all;
    padding-top: 25%;
  }

  .container {
    // padding: 67px 35px;

    @include media-breakpoint-up(lg) {
      padding: 82px 35px;
    }

    @include media-breakpoint-up(xl) {
      padding: 82px 110px;
    }
  }

  .offset {
    background-color: $white;

    @include media-breakpoint-up(lg) {
      background-color: transparent;
    }

    .container {
      padding: 67px 35px;

      @include media-breakpoint-up(lg) {
        padding: 82px 35px;
      }

      @include media-breakpoint-up(xl) {
        padding: 82px 110px;
      }

      &.p-portfolio {
        @include media-breakpoint-up(xl) {
          padding-top: 110px;
          padding-bottom: 110px;
        }
      }
    }
  }

  .bg-offset {
    position: absolute;
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 100vw;
    }

    @include media-breakpoint-up(lg) {
      width: 100vw;
    }

    @include media-breakpoint-up(2xl) {
      width: 150vw;
    }

    &.offset-left {
      left: 0;
      top: 0;

      @include media-breakpoint-up(md) {
        left: -10px;
      }

      @include media-breakpoint-up(lg) {
        left: 20px;
      }

      @include media-breakpoint-up(xl) {
        left: 40px;
      }

      // @include media-breakpoint-up(2xl) {
      //   left: -50px;
      // }

      // @include media-breakpoint-up(3xl) {
      //   left: -170px;
      // }
    }

    &.offset-right {
      right: 0;
      top: 0;

      @include media-breakpoint-up(md) {
        right: -10px;
      }

      @include media-breakpoint-up(lg) {
        right: 20px;
      }

      @include media-breakpoint-up(xl) {
        right: 40px;
      }

      // @include media-breakpoint-up(2xl) {
      //   right: -170px;
      // }

      // @include media-breakpoint-up(3xl) {
      //   left: -170px;
      // }
    }
  }

  .portfolio {
    .bg-offset {
      &.offset-right {
        @include media-breakpoint-up(lg) {
          right: 0;
        }

        @include media-breakpoint-up(xl) {
          right: 60px;
        }
      }
    }
  }

  .image-wrapper {
    .icon {
      width: 80%;
      padding: 0 0 3rem 0;

      @include media-breakpoint-up(xl) {
        width: 100%;
        padding: 4rem 0 2rem 0;
      }
    }
  }

  .bg-image.icon {
    background-color: transparent;
    background-size: 65%;

    @include media-breakpoint-up(lg) {
      background-size: 100%;
    }

    @include media-breakpoint-up(xl) {
      background-size: 100%;
    }
  }
}

#pageFooter {
  background: $green-300;

  img {
    width: 50%;

    @include media-breakpoint-up(lg) {
      width: 100%;
    }
  }

  .nav {
    .nav-item {
      display: flex;
      align-items: center;
      text-transform: uppercase;
      text-align: center;
      font-size: $font-size-base;
      font-weight: $font-weight-bold;
      letter-spacing: 1px;
      font-size: 0.8rem;

      @include media-breakpoint-up(xl) {
        font-size: 1rem;
      }

      .nav-link {
        color: $white;
        padding: 0.8rem 0;

        @include media-breakpoint-up(lg) {
          padding: 0 1rem;
        }
      }
    }
  }

  .footer-address {
    @include remCalc(13px);
    text-transform: capitalize;
  }
}
