// TODO: Enter all Bootstrap overrides here...
.blockquote-footer {
  &:before {
    content: none;
  }
}

.carousel-item {
  min-height: 180px;
  @include media-breakpoint-down(sm) {
    min-height: 250px;
  }
}
