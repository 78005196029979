// https://www.cognitoforms.com/blog/6/diy-preloader-for-your-forms
// Preloader behavior for cognito form
.preloader {
  display: none;
  width: 100%;
  text-align: center;
  img {
    width: 50px;
    margin: auto;
  }
}

// Override the default invisibility if the preloader doesn't have a form after it.
.preloader:last-child {
  display: block !important;
}

/* Create a spinning animation and name it "spin" */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.preloader img {
  display: block;
  animation-name: spin;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.cognito {
  .c-forms-form {
    :not(.c-question) > .c-label {
      font-family: $font-family-sans-serif !important;
      margin-bottom: 10px;
      font-size: $font-size-sm;
    }

    button,
    #c-submit-button {
      border-radius: 0 !important;
      font-family: $font-family-sans-serif !important;
      padding: 10px 30px;
      width: auto;
      float: left;
    }
  }
}