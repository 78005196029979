#about {
  .hero {
    padding-top: 45%;

    @include media-breakpoint-up(md) {
      padding-top: 25%;
    }
  }

  .success {
    .block {
      &:first-child:before {
        @include media-breakpoint-up(md) {
          content: '';
          background: url('../img/about/tvp-about-tenant-frame-01.svg')
            no-repeat 0 0;
          position: absolute;
          top: 0;
          left: 7px;
          width: 145px;
          height: 105px;
        }

        @include media-breakpoint-up(lg) {
          left: 35px;
        }
      }

      &:last-child:after {
        @include media-breakpoint-up(md) {
          content: '';
          background: url('../img/about/tvp-about-tenant-frame-02.svg')
            no-repeat 0 0;
          position: absolute;
          top: 0;
          right: 7px;
          width: 145px;
          height: 105px;

          @include media-breakpoint-up(lg) {
            right: 35px;
          }
        }
      }
    }

    .block-alt {
      &:first-child:before {
        @include media-breakpoint-up(md) {
          content: '';
          background: url('../img/about/tvp-about-tenant-frame-03.svg')
            no-repeat 0 0;
          position: absolute;
          bottom: 0;
          left: 7px;
          width: 145px;
          height: 105px;
        }

        @include media-breakpoint-up(lg) {
          left: 35px;
        }
      }

      &:last-child:after {
        @include media-breakpoint-up(md) {
          content: '';
          background: url('../img/about/tvp-about-tenant-frame-04.svg')
            no-repeat 0 0;
          position: absolute;
          bottom: 0;
          right: 0;
          width: 145px;
          height: 105px;
        }

        @include media-breakpoint-up(lg) {
          right: 35px;
        }
      }
    }

    .image-wrapper {
      .icon {
        width: 30%;
      }
    }
  }

  .resources {
    .nav .nav-item {
      &.active,
      &:hover {
        color: $white;
      }
    }
    .card-wrapper {
      .card {
        &:before {
          content: '';
          display: block;
          width: 15%;
          @include transition;
        }

        &:hover:before {
          width: 100%;
        }

        a {
          text-decoration: none;
        }

        .date {
          font-size: $font-size-sm;
        }
      }

      #nav-recommended {
        .card:before {
          border-bottom: 8px solid $orange-light;
        }
      }

      #nav-portfolio {
        .card:before {
          border-bottom: 8px solid $teal;
        }
      }
    }
  }
}
